import React, { useContext, useEffect, useMemo, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AuthService from "../../../services/AuthService";
import { useAuth } from "../../../context";
import { useNavigate } from "react-router-dom";
// import "../../styles/index.css";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addMinutes, isAfter, parseISO } from "date-fns";
import { hashMatched } from "../../../utils/decodeOtp";
import { isOTPExpired } from "../../../utils/isOTPExpired";
import Loading from "../../../assets/images/loader.gif";
import { handleOTP } from "../../../utils/handleOTP";
import axios from 'axios';

// const options = {'one-time': [{name:'Order Status',path:''},{name:}]}

function OTPVerify() {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const [resend, setresend] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const url = window.location.href;
  const parts = url.split('/');
  const domain = parts[0] + '//' + parts[2] + '/';
  

  const cId =
    state?.user?.customer_id || JSON.parse(localStorage.getItem("customer_id"));
  const email =
    state?.user?.email || JSON.parse(localStorage.getItem("user_email"));
  const { secret, time } = JSON.parse(localStorage.getItem("user_secret"));

  const validationSchema = Yup.object().shape({
    OTP: Yup.string()
      .max(6, "One time password must be 6 digits")
      .required("One time password is required"),
  });
  
  // modified
  const saveCustomerData = async (payload) => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      payload.ip = res.data.ip;
      payload.domain = domain;
      await AuthService.saveUserDetails(payload);
      return true;
    } catch (error) {
      return false;
    }
  };

  // modified
  const handleResponse = async (response) => {
    if (response.status) {
      const payload = {
        email: response?.email,
        first_name: response?.first_name,
        last_name: response?.last_name,
        customer_id: response?.id,
        token: response?.token?.accessToken,
      };
      localStorage.token = response?.token?.accessToken;
      const dataSaved = await saveCustomerData(payload);
      if (dataSaved) {
        return dispatch({ type: "OTP_VERIFY", payload });
      }
    }
  };

  // modified
  const handleSubmit = async ({ OTP }) => {
    setIsButtonDisabled(true);
    OTP = handleOTP(OTP);

    try {
      const matched = hashMatched(OTP.toString(), secret.toString());
      const expired = isOTPExpired(time);

      if (!matched || expired) {
        toast.error("Invalid one time password", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsButtonDisabled(false);
        return setresend(true);
      }

      const response = await AuthService.verifyOTP({
        otp: OTP,
        customerId: cId,
      });
      setresend(false);
      handleResponse(response);
    } catch (error) {
      setresend(true);
      setIsButtonDisabled(false);
      toast.error("Invalid one time password", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleLoginResponse = async (response) => {
    if (response.status) {
      const payload = {
        email: response?.data?.email,
        first_name: response?.data?.first_name,
        customer_id: response.customer_id,
        secret: response.secret,
      };
      dispatch({ type: "LOGIN", payload });
    }
  };

  const resendOTP = async (data) => {
    if (data) {
      try {
        const response = await AuthService.login({ email });
        toast.success("One time password sent successfully to your email", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleLoginResponse(response);
      } catch ({ response }) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setresend(true);
    }, 30000);
  }, [resend]);

  return (
    <main>
      <div className="container pagePadding">
      <ToastContainer />
      <div className="iv-top text-center">
        <h2 className="iv-title">Verify your one time password</h2>
        <div className="blue-line"></div>
      </div>

      <div>
        <p style={{ fontSize: "17px", fontWeight: "600" }}>
          Please check your SPAM box if you do not see the one time password email
        </p>
      </div>

      <div className="opt-box">
        <div className="container">
          <Formik
            className="wrapper"
            initialValues={{ OTP: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="locateAccount">
                <div className="form-group">
                  <Field
                    name="OTP"
                    type="number"
                    placeholder="One time password"
                    className="form-control"
                  />
                  {errors.OTP && touched.OTP ? (
                    <h5 style={{ color: "red" }}>{errors.OTP}</h5>
                  ) : null}
                </div>
                <button
                  disabled={isButtonDisabled}
                  // disabled={true}
                  type="submit"
                  className={`btn ${isButtonDisabled && "disabled"}`}
                >
                  {isButtonDisabled && (
                    <img
                      src={Loading}
                      alt=""
                      style={{ width: "30px", paddingRight: "5px" }}
                    />
                  )}
                  Submit
                </button>
                {resend && (
                  <h5
                    style={{
                      cursor: "pointer",
                      marginTop: "20px",
                      textDecoration: "underline",
                      color: "#37a8df",
                    }}
                    onClick={() => {
                      resendOTP(email);
                      setresend(false);
                    }}
                  >
                    Resend One Time Password
                  </h5>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      </div>
    </main>
  );
}

export default OTPVerify;
